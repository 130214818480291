module.exports = {
  //PARAMETERS
  developmentFeatures: true, //false or not assigned to disable development features
  captchaTokenFront: "6LcjXu0aAAAAABpuC2mj1E5H7dsJ5hQ5QThJkubd",
  stripe_pk:
    "pk_live_51MBekFAd02IkV8oWpF9sp2NpELDPjpNlY7CdQe3onixOPuImRgROIKl8a6OEvmVpQ7LdVjtP5jCkR91x1wuCRPKb00rGTHiO9c", //TEST MADRID
  open_DOORS: false,
  more_payment_methods: false,
  access_code_digits: 4,
  "color-primary": "#28c76f",
  "color-map-nodisponible": "#cda077", // cda077 keep this color if the company doesnt ask to change it to their primary color
  useAnalytics: false,
  use_MERCHANT: false, //USE mercantilRegister param
  use_FAX: false,
  use_LOCATION_PREP: false,
  use_StorageGroups: true,
  use_TEST_DATA: false, //AUTO FILL FORMS WITH TEST DATA

  //FEATURES
  ALLOW_CHANGE_STORAGE: false, //ALLOW CHANGE STORAGE FEATURE
  
  //CHECKOUT PARAMETERS
  USE_RETENTION: false, //Allowed 19% retention
  use_PRORRATION: true, //SHOW PRORRATION AT CHECKOUT
  use_CONTRACTS: false, //SHOW SIGNABLE CONTRACTS AT CHECKOUT
  use_SIGNABLE: true,
  use_ASSURE: false, //SHOW ASSURES AT CHECKOUT
  use_ASSURE_PRORRATION: false, //APPLY PRORRATION TO ASSURES AT CHECKOUT
  use_ASSURE_EXTENSION: false, //SHOW ASSURE EXTENSION
  use_DEPOSIT: true, //SHOW DEPOSIT AT CHECKOUT
  
  //ADD CUSTOMER CHECKOUT PARAMETERS
  allow_CREATE_CUSTOMER_PURCHASE: false, //ALLOWS TO CREATE A SINGLE CUSTOMER WITHOUT PURCHASE
  allow_NORMAL_PURCHASE: true, //ALLOWS NORMAL PURCHASE
  allow_LACK_PURCHASE: false, //ALLOWS LACK PURCHASE
  allow_BUY_PRODUCTS_PURCHASE: false, //ALLOWS BUY PRODUCTS PURCHASE
  allow_BOOKING_PURCHASE: false, //ALLOWS BOOKING PURCHASE

  //PAYMENT PARAMETERS
  use_CARD_PAYMENT_FOR_WORKERS: true, //ENABLE CARD PAYMENTS
  use_SEPA_DEBIT_PAYMENT_FOR_WORKERS: true, //ENABLE SEPA_DEBIT PAYMENTS
  use_TRANSFER_PAYMENT_FOR_WORKERS: false, //ENABLE TRANSFER PAYMENTS
  use_CARD_PAYMENT_FOR_CUSTOMERS: true, //ENABLE CARD PAYMENTS
  use_SEPA_DEBIT_PAYMENT_FOR_CUSTOMERS: true, //ENABLE SEPA_DEBIT PAYMENTS
  use_TRANSFER_PAYMENT_FOR_CUSTOMERS: false, //ENABLE TRANSFER PAYMENTS
  use_PAY_AT_SHOP: false, //ENABLE TRANSFER PAYMENTS

  //PARAM NEW COMPANY
  withLanding: false,
  withIndoorOpening: true, //tiene puerta principal interna
  withStorageOpening: true, //storages doors

  companyName: "CABE TRASTEROS",
  phone: "900 923 924",
  city: "Madrid",
  province: "Madrid",
  email: "info@cabe.es",
  address: "PASEO DE LOS PARQUES 4, 28109 ALCOBENDAS",
  postal_code: "28109",
  storageNaming: "BOX",
  commercialName: "CABE TRASTEROS",
  payments_companyName: "CABE KEEP AND LOCK, S.A",
  payments_NIF: "A09634668",
  mercantilRegister: "",
  accountant_email: "t.prats@rentacorporacion.com",
  contract_email: "info@cabe.es",
  url: "https://cabe.es/",
  branch: "CABE",

  //INVOICE PARAMETERS
  firstSizeM3: false,
  use_IRPF: false,
  IVA: 21, //IN PERCENT 21%
  RETENTION: 19, //IN PERCENT 19%
  CENTERNAME:'ES',
  automaticPayment:false,

  //URL
  workersURL:"/workers/login",

  CUSTOM_IMAGE_URL: 'https://cabe.es/wp-content/uploads/2022/08/Menu.svg',
  perPageLimit:5,
  showUnsubcribeProcess:true
};

// config-front.js OLD
/*
module.exports = {
    //PARAMETERS
    'developmentFeatures': true, //false or not assigned to disable development features
    'captchaTokenFront': '6LcjXu0aAAAAABpuC2mj1E5H7dsJ5hQ5QThJkubd',
    'stripe_pk': 'pk_test_51Jc6iJDPH7ctlvyHuGcjNUKuSnZ8uTmCRJdo0LFxG8V6SWVF18pVnzBI9hlrJGXpuNQ4UlpyvyI5sofN4Ak7V3wl00dOzxzNwY', //TEST MADRID
    'USE_RETENTION': false, //Allowed 19% retention
    'use_PRORRATION': true, 
    'open_DOORS': false,
    'more_payment_methods': false,
    'access_code_digits': 4,
    'use_CONTRACTS': true,
    
    //PARAM NEW COMPANY
    'withLanding': true,
    'withIndoorOpening': false, //puertas internas trasteros

    'companyName' : "",
    'phone' : "626 77 89 83",
    'city' : "Elche",
    'province' :"Alicante",
    'email' : "evvant.tech@gmail.com",
    'address' : "AVENIDA DE LA LIBERTAD NÚMERO 2, ALTILLO",
    'postal_code' :  "03202",
    'storageNaming' : "BOX",
    'commercialName' : "m² Trasteros Trasteros",

    // 'mercantilRegister': 'Alicante, en el tomo 1.561, Folio 111, Sección 8, Hoja A-19459',
    'mercantilRegister': 'Inscrita en Registro Mercantil Alicante - Tomo 1.561 Folio 111 Sección 8 Hoja A-19459',
    'use_MERCHANT': true,
    'use_FAX': false,
    'use_LOCATION_PREP': false,
    'use_assure': true,
    'use_ProrationAsure': false,
    'developmentFeatures': true, //false or not assigned to disable development features
    'use_Contracts': true,
    'use_StorageGroups': true,
    'accountant_email': 'esolis@gefrumon.com',
    'contract_email': 'admin@m2trasterosurbanos.es',

    'color-primary': '#cda077',

    'firstInvoiceDate': '2021-10',

    'firstSizeM3': true,
    'use_IRPF': true,
    'useAnalytics': false,

    'payments_companyName': 'M2 SELF STORE, S.L',
    'payments_NIF': 'B88513023',
};*/
